import { Button as BaseButton } from 'baseui/button';
import styled from '@emotion/styled';

const Button = styled(BaseButton)`
  background-color: ${p => (p.isSelected ? '#7A5AF8' : 'transparent')};
  padding-left: 20px;
  padding-right: 20px;
  height: ${p => p.height || '30px'};
  line-height: 30px;
  margin-top: ${p => p.marginTop || '16px'};
  border-radius: 20px;
  font-size: 12px;
`;

export default Button;
